<template>
  <div id="about-textree">
    <div class="about-content">
      <div class="content ">
        <div class="about-title  animate__animated animate__fadeInLeft">Our Team</div>
        <hr>
        <div class="contents">
          <div class=" animate__animated animate__fadeInDown ">
            <div class="content-title">#컨텐츠 제작팀</div>
            <div class="content-desc">제품을 사용자에게 어떻게 전달할 것인지 기획하며 전문적인 편집 툴을 사용하여 테크니컬 라이터와 테크니컬 일러스트레이터가 기업이 개발한 제품에 대한 사용설명서 제작을 도와 드립니다.</div>
          </div>
          <div class="animate__animated animate__fadeInDown animate__delay-1s">
            <div class="content-title">#퍼블리싱팀</div>
            <div class="content-desc">다양한 매체 (웹, eBook, 인쇄)에 최적화된 컨텐츠를 제작/관리해 드리며, HTML 기반의 반응형 웹 컨텐츠 부터 모바일 운영체제(안드로이드, 윈도우, IOS 등)에 적합한 애플리케이션 기반 컨텐츠도 제작해 드립니다.</div>
          </div>
          <div class="animate__animated animate__fadeInDown animate__delay-2s">
            <div class="content-title">#번역, 현지화팀</div>
            <div class="content-desc">언어별 인적 자원을 끊임없이 발굴하고 검증할 뿐만 아니라 지속적으로 평가하고 관리합니다. 또한 QA 매니저와 원어민 직원이 최고의 번역 품질을 유지합니다.</div>
          </div>
          <div class="animate__animated animate__fadeInDown animate__delay-3s">
            <div class="content-title">#솔루션 개발팀</div>
            <div class="content-desc">XML을 기반으로 하는 구조화 문서(Structured Document) 엔지니어링 기술력을 보유하여 기업의 업무 환경에 적합한 컨텐츠 제작 프로세스 설계 및 시스템 구축 서비스를 제공합니다.</div>
          </div>
        </div>
      </div>
      <div class="cover-container animate__animated animate__fadeInRight">
        <div class="cover animate__animated animate__fadeInRight" style="background-image: url('/assets/images/about/1334391227.png')"></div>
        <div class="cover animate__animated animate__fadeInRight" style="background-image: url('/assets/images/about/1334391228.png')"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style scoped>
div#about-textree {
  height: 100%;
  max-width: 1600px;
  margin: 0 auto 0;
}
div.about-title {
  font-family: "Mpntserrat";
  font-size: 60px;
  font-weight: bold;
  color: #008ED6;
}

hr {
  max-width: 100px;
  height: 5px;
  background: #F1B500;
  border: none;
  margin: 48px 0;
}

div.about-content {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
}
div.cover-container {
  background-image: url('/assets/images/pattern-yellow.png'), url('/assets/images/pattern-blue.png');
  background-position: left bottom, right top;
  background-repeat: no-repeat, no-repeat;
  flex: 0 0 calc(50% - 48px);
  height: 90%;
  display: flex;
  flex-flow: column;
}
div.cover {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: left;
  background-size: 80%;
}
div.cover:first-child {
  background-position: left;
}
div.cover:last-child {
  background-position: right;
}

div.contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
div.contents > div {
  flex: 0 1 calc(50% - 48px);
  padding-right: 48px;
}

div.content {
  flex: 0 0 calc(50% - 48px);
  margin-top: 100px;
}
div.content-title {
  font-family: "Nanum Gothic";
  font-size: 18px;
  font-weight: bold;
  line-height: 33px;
  letter-spacing: 5;
  color: #008ED6;
}
div.content-desc {
  font-family: "Nanum Gothic";
  font-size: 18px;
  line-height: 33px;
  color: #707070;
  margin: 24px 0;
}
div.content-desc ul {
  padding-left: 20px;
}

@media(max-width: 1440px) {
  div#about-textree {
    width: calc(100% - 48px);
    padding: 0 24px;
    max-width: 100%;
  }
  div.content {
    flex-basis: 50%;
  }
  div.cover-container {
    flex-basis: 50%;
  }
  div.content-title {
    line-height: 1.5;
  }
  div.content-desc {
    line-height: 1.8;
  }
}
@media(max-width: 1024px) {

  div.contents > div {
    padding-right: 24px;
    flex-basis: calc(50% - 24px);
  }
  div.about-title {
    font-size: 48px;
  }
  div.content-title {
    line-height: 1.5;
  }
  div.content-desc {
    line-height: 1.5;
    font-size: 16px;
  }
}

@media(max-width: 768px) {
  div.content {
    margin: 0;
    flex-basis: 60%;
  }
  div.cover-container {
    flex-basis: calc(40% - 24px);
    margin-left: 24px;
  }
  div.about-title {
    font-size: 35px;
  }
  hr {
    margin: 24px 0;
  }
  div.contents > div {
    padding-right: 0;
    flex-basis: calc(50% - 12px);
  }
  div.content-desc {
    font-size: 16px;
  }
}

@media(max-width: 576px) {
  div.about-content {
    flex-direction: column;
  }
  div.cover-container {
    margin-left: 0;
  }
  div.content-desc {
    font-size: 12px;
    margin: 0;
  }
  div.cover:first-child {
    margin-left: -24px;
    background-size: cover;
  }
  div.cover:last-child {
    margin-left: 24px;
    margin-right: -24px;
    background-size: cover;
  }
}

@media(max-height: 680px) {
  div.about-title {
    font-size: 24px;
  }
  hr {
    margin: 12px 0;
  }
  div.content-title {
    font-size: 12px;
  }
  div.cover-container {
    flex-basis: 25%;
  }
}
</style>
